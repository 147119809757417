<template>
  <div class="index-container">
    <dataFilter ref="dataFilter" :filter="filter">
      <el-button type="primary" @click="handleQuery()">查询</el-button>
      <el-button type="warning" @click="handleExport()">导出</el-button>
    </dataFilter>
    <div class="tab-content">
      <el-table border size="mini" :data="datas" style="width: 100%">
        <el-table-column label="基本信息" show-overflow-tooltip align="center">
          <el-table-column
            prop="Check.Code"
            fixed="left"
            label="测试编号"
            :width="180"
          >
          </el-table-column>
          <el-table-column label="客户姓名" :min-width="80">
            <template slot-scope="scope">
              <div>
                {{ scope.row.Check.Member.RealName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Check.Member.Mobile"
            label="手机号"
            :width="120"
          >
          </el-table-column>
          <el-table-column prop="Check.Member.Sex" label="性别" :width="60">
          </el-table-column>
          <el-table-column label="报告日期" :min-width="120">
            <template slot-scope="scope">
              <el-tag type="success">{{
                format.dateFormat(scope.row.Check.ReportDate)
              }}</el-tag>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="得分"
          prop="Score"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column label="静态测试" show-overflow-tooltip align="center">
          <el-table-column label="左足" show-overflow-tooltip align="center">
            <el-table-column
              label="面积前"
              prop="StaticLeftAreaFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积后"
              prop="StaticLeftAreaBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积"
              prop="StaticLeftArea"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="最大压力"
              prop="StaticLeftMaxHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="平均压力"
              prop="StaticLeftAveHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比前"
              prop="StaticLeftHeatRatioFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比后"
              prop="StaticLeftHeatRatioBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比"
              prop="StaticLeftHeatRatio"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布前"
              prop="StaticLeftCoverFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布后"
              prop="StaticLeftCoverBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="重量"
              prop="StaticLeftWeight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="鞋码"
              prop="DataLeftSize"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="右足" show-overflow-tooltip align="center">
            <el-table-column
              label="面积前"
              prop="StaticRightAreaFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积后"
              prop="StaticRightAreaBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="面积"
              prop="StaticRightArea"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="最大压力"
              prop="StaticRightMaxHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="平均压力"
              prop="StaticRightAveHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比前"
              prop="StaticRightHeatRatioFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="压力占比后"
              prop="StaticRightHeatRatioBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比"
              prop="StaticRightHeatRatio"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布前"
              prop="StaticRightCoverFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布后"
              prop="StaticRightCoverBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="重量"
              prop="StaticRightWeight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="鞋码"
              prop="DataRightSize"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            
          </el-table-column>
          <el-table-column
            label="峰值压力位置"
            prop="FengZhiYaLiPostion"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="姿势测试" show-overflow-tooltip align="center">
          <el-table-column label="左足" show-overflow-tooltip align="center">
            <el-table-column
              label="面积前"
              prop="PostureLeftAreaFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积后"
              prop="PostureLeftAreaBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积"
              prop="PostureLeftArea"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="最大压力"
              prop="PostureLeftMaxHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="平均压力"
              prop="PostureLeftAveHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比前"
              prop="PostureLeftHeatRatioFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比后"
              prop="PostureLeftHeatRatioBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比"
              prop="PostureLeftHeatRatio"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布前"
              prop="PostureLeftCoverFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布后"
              prop="PostureLeftCoverBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="重量"
              prop="PostureLeftWeight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="右足" show-overflow-tooltip align="center">
            <el-table-column
              label="面积前"
              prop="PostureRightAreaFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积后"
              prop="PostureRightAreaBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="面积"
              prop="PostureRightArea"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="最大压力"
              prop="PostureRightMaxHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="平均压力"
              prop="PostureRightAveHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比前"
              prop="PostureRightHeatRatioFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比后"
              prop="PostureRightHeatRatioBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="压力占比"
              prop="PostureRightHeatRatio"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布前"
              prop="PostureRightCoverFront"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="分布后"
              prop="PostureRightCoverBack"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="重量"
              prop="PostureRightWeight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="动态测试" show-overflow-tooltip align="center">
          <el-table-column label="左足" show-overflow-tooltip align="center">
            <el-table-column
              label="状态"
              prop="DynamicLeftShape"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="CPEI"
              prop="DynamicLeftCPEI"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积"
              prop="DynamicLeftArea"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="最大压力"
              prop="DynamicLeftMaxHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="平均压力"
              prop="DynamicLeftAveHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="右足" show-overflow-tooltip align="center">
            <el-table-column
              label="状态"
              prop="DynamicRightShape"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="CPEI"
              prop="DynamicRightCPEI"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="面积"
              prop="DynamicRightArea"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="最大压力"
              prop="DynamicRightMaxHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="平均压力"
              prop="DynamicRightAveHeat"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="足形" show-overflow-tooltip align="center">
          <el-table-column label="左足" show-overflow-tooltip align="center">
            <el-table-column
              label="外旋度数"
              prop="DataEveLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="内旋度数"
              prop="DataInvLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="足长"
              prop="DataFootLeftSize"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="足弓类型"
              prop="DataArchLeftType"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="右足" show-overflow-tooltip align="center">
            <el-table-column
              label="外旋度数"
              prop="DataEveRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="内旋度数"
              prop="DataInvRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="足长"
              prop="DataFootRightSize"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="足弓类型"
              prop="DataArchRightType"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :page-sizes="pageSizeList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>
  <script>
import dataFilter from "./components/dataFilter.vue";
import { exportData, getPageList } from "@/api/library/checkFootPressure.js";
export default {
  components: { dataFilter },
  data() {
    return {
      loading: false,
      total: 0,
      pageIndex: 1,
      pageSizeList: [10, 20, 30, 50, 80],
      pageSize: 10,
      datas: [],
      filter: {},
    };
  },
  methods: {
    handleExport() {
      this.loadInstance = this.$loading({
        lock: true,
        text: "正在导出...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.filter = this.$refs.dataFilter.getfilter();
      exportData(this.filter)
        .then((res) => {
          this.loadInstance.close();
          if (res) {
            window.open(this.baseUrl + res);
          }
        })
        .catch((ex) => {
          this.msg.errorMsg("导出出错：" + ex);
          this.loadInstance.close();
        });
    },
    handleQuery() {
      let tempfilter = this.$refs.dataFilter.getfilter();
      this.filter = tempfilter;
      this.getData();
    },
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      this.loading = true;
      getPageList(this.filter)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.datas = res.Items;
            this.total = res.Total || res.Items.length;
          }
        })
        .catch((ex) => {
          this.loading = false;
          this.total = 0;
          this.datas = [];
        });
    },
    pageSizeChange(val) {
      var pageSize = val;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
  },
  mounted() {
    this.handleQuery();
  },
};
</script>
  <style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>