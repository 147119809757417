

import request from '@/utils/request'

export function exportData(data) {
  return request({
    url: 'CheckFootPressure/exportData',
    method: 'post',
    data
  })
}

export function getPageList(data) {
  return request({
    url: 'CheckFootPressure/getPageList',
    method: 'post',
    data
  })
}
